<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="indigo lighten-5" flat>
        <BackButton />
        <v-spacer></v-spacer>
        <div class="font-italic font-weight-medium">Картка правила пуш-повідомлення</div>
      </v-toolbar>
      <v-container v-if="isLoaded" fluid>
        <!--        TODO change logic from lang to index-->
        <!--        <GetFormLanguages @changeFormLanguage="onChangeFormLanguage" />-->
        <v-row>
          <v-col cols="12" lg="5" xl="4">
            <SettingTable :table-data="getModifiedSetting1" />
          </v-col>
          <v-col cols="12" lg="5" offset-lg="2" xl="4" offset-xl="1">
            <SettingTable :table-data="getModifiedSetting2" />
          </v-col>
        </v-row>
        <div class="d-flex justify-end">
          <v-btn @click="showPreviewModal = true" class="mr-2" color="primary" small outlined>попередній перегляд</v-btn>
        </div>
      </v-container>
    </v-card>
    <PreviewMobileDialog
      @closeModal="showPreviewModal = false"
      :showModal="showPreviewModal"
      :notification="currentNotificationSettings"
      :contentIndex="contentIndex"
    />
  </v-container>
</template>

<script>
import BackButton from '@/components/common/BackButton'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'PushNotificationSettingCard',

  components: {
    BackButton,
    // GetFormLanguages: () => import('@/components/common/forms/GetFormLanguages'),
    SettingTable: () => import('@/components/support/push_notification/SettingTable'),
    PreviewMobileDialog: () => import('@/components/dialogs/PreviewMobileDialog'),
  },

  data: () => ({
    isLoaded: true,
    showPreviewModal: false,
    contentIndex: 0,
  }),

  computed: {
    ...mapState('pushNotification', ['currentNotificationSettings']),
    ...mapGetters('pushNotification', ['getModifiedSetting1', 'getModifiedSetting2', 'getAvailableNotificationLanguages']),

    currentNotificationId() {
      return this.$route.params.notificationId
    },
  },

  created() {
    this.initialize()
  },

  beforeDestroy() {
    this.setCurrentPushNotificationSettingsEmpty()
  },

  methods: {
    ...mapActions('pushNotification', ['loadCurrentPushNotificationSettings']),
    ...mapMutations('pushNotification', ['SET_CURRENT_NOTIFICATION_SETTINGS', 'SET_CURRENT_PUSH_CONTENT_INDEX']),

    async initialize() {
      if (this.currentNotificationId) {
        this.isLoaded = false
        this.SET_CURRENT_PUSH_CONTENT_INDEX(this.contentIndex)
        await this.loadCurrentPushNotificationSettings(this.currentNotificationId)
        this.isLoaded = true
      }
    },
    setCurrentPushNotificationSettingsEmpty() {
      this.SET_CURRENT_NOTIFICATION_SETTINGS({})
    },
    // TODO change logic from lang to index
    onChangeFormLanguage(lang = 'uk') {
      this.SET_CURRENT_PUSH_CONTENT_INDEX(lang)
    },
  },
}
</script>

<style scoped></style>
